<template>
  <div class="logs">
    <h1>Vtex Logs</h1> 
    <p>Acompanhe os logs da ferramenta</p>

    <div class="page-container">
        <b-table striped hover :items="logs" :fields="fields"></b-table>
        <hr />
        <paginate
            v-model="page"
            :page-count="last_page"
            :click-handler="functionName"
            :prev-text="'<'"
            :next-text="'>'" 
            :container-class="'pagination'">
        </paginate>
    </div>

  </div>
</template>

<script>
import { listarLogs } from "@/api/vtex";
import { filterDataHora } from "@/utils/date";
export default {
    data() {
        return {
            page: 1,
            fields: [
                {
                    key: 'id',
                    sortable: true,
                },
                {
                    key: 'itemId',
                    label: 'ID Referência'
                },
                {
                    key: 'log',
                    label: 'Objeto'
                },
                {
                    key: 'type',
                    label: 'Tipo'
                },
                {
                    key: 'status',
                    label: 'Status'
                },
                {
                    key: 'message',
                    label: 'Mensagem'
                },
                {
                    key: 'created_at',
                    label: 'Criado em',
                    formatter: (value) => {
                        return filterDataHora(value)
                    }
                },
                {
                    key: 'updated_at',
                    label: 'Última tentativa',
                    formatter: (value) => {
                        return filterDataHora(value)
                    }
                }
            ],
        }
    },
    filters: {
        filterDataHora
    },
    computed: {
        logs() {
            return this.$store.state.vtex.logs;
        },
        last_page() {
            return this.$store.state.vtex.logs_last_page;
        },
        per_page() {
            return this.$store.state.vtex.logs_per_page;
        },
    },
    methods: {
        listarLogs,
        functionName(num) {
            this.page = num;
            this.listarLogs(this.page);
        }
    },
    mounted() {
        this.listarLogs(this.page);
    }
};
</script>

<style lang="scss" scoped>
.logs {
  height: 100%;

  .page-container {
    margin: 30px 0;
  }
}
</style>
